<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "รายละเอียดใบยกเลิกสั่งซ่อม",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      // tableData: tableData,

      title: "รายละเอียดใบยกเลิกสั่งซ่อม",
      items: [
        {
          text: "หน้าหลัก",
        },
        {
          text: "รายละเอียดใบยกเลิกสั่งซ่อม",
          active: true,
        },
      ],
      // optionStatus: [
      //   {
      //     status: "DFT",
      //   },
      //   {
      //     status: "SUBMIT",
      //   },
      //   {
      //     status: "APV",
      //   },
      // ],
      cancelId: this.$route.params.cancelId,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      // cancelstatus: "",
      status: "",
      payment: "",
      type: "",
      roId: "",
      serviceRo: [],
      // selectMode: "single",
      userBranchId: "",
      branchId: "",
      cancelDate: "",
      recipient: "",
      note: "",
      rowsUser: [],
      submitform: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",
      userBranch: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      overlayFlag: false,
    };
  },
  validations: {
    note: {
      required,
    },
    cancelDate: {
      required,
    },
    recipient: {
      required,
    },
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    this.getData();
    this.getMaster();
  },
  created() {},
  methods: {
    customLabel({ name, familyName }) {
      return `${name}  ${familyName != null ? familyName : ""}`;
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/ro-cancellation/show", {
          params: {
            cancelId: this.cancelId,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.cancelDate = response.data.data.cancelDate;
          this.status = response.data.data.status;
          // this.cancelstatus = {status: response.data.data.status};
          //   this.recipient = {userId: response.data.data.cancelBy, name: };
          this.note = response.data.data.note;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.isHidden = true;
        });
    },
    tooltipForm() {
      // console.log(this.$v)
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitUpdateCancel();
      }
    },
    submitUpdateCancel: function() {
      useNetw
        .put("api/ro-cancellation/update", {
          cancelId: this.cancelId,
          cancelDate: this.cancelDate != null ? this.cancelDate : undefined,
          recipient:
            this.recipient.userId != null ? this.recipient.userId : undefined,
          note: this.note != null ? this.note : undefined,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          //   this.$router.push({
          //     name: "extra-service",
          //   });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
        });
    },
    submitCancel: function() {
      useNetw
        .put("api/ro-cancellation/submit", {
          cancelId: this.cancelId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
          //   this.$router.push({
          //     name: "extra-service",
          //   });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message + '<br>' + err.response.data.validationMessage),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
        });
    },
    submitApv: function() {
      useNetw
        .put("api/ro-cancellation/approve", {
          cancelId: this.cancelId,
          status: "APV",
          // status: this.cancelstatus.status,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
          //   this.$router.push({
          //     name: "extra-service",
          //   });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message + '<br>' + err.response.data.validationMessage),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
        });
    },
    getMaster: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/ro-cancellation/user", {
          params: {
            page: this.currentPage,
            perPage: 100,
            "branchId[]":
              this.userBranchId.branchId != null
                ? this.userBranchId.branchId
                : this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="m-4">
              <h4><span class="badge bg-info" v-if="status == 'SUBMIT'">รอยืนยันการยกเลิกใบสั่งซ่อม</span></h4>
              <h4><span class="badge bg-warning" v-if="status == 'DFT'">ร่าง</span></h4>
              <h4><span class="badge bg-danger" v-if="status == 'APV'">ยกเลิกใบสั่งซ่อมเรียบร้อยแล้ว</span></h4>
            </div>
            <hr />
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-start">
                    <div class="col-6 col-sm-6 col-md-6">
                      <div class="mb-3 position-relative">
                        <code> * </code>วันที่ยกเลิกใบสั่งซ่อม:
                        <date-picker
                          v-model="cancelDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          :class="{
                            'is-invalid': submitform && $v.cancelDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.cancelDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.cancelDate.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-6">
                      <div class="mb-3 position-relative">
                        <code> * </code>ผู้รับ:
                        <multiselect
                          v-model="recipient"
                          label="name"
                          :options="rowsUser"
                          :custom-label="customLabel"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.recipient.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.recipient.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.recipient.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code>สถานะ:
                        <multiselect
                          v-model="cancelstatus"
                          label="status"
                          :options="optionStatus"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.recipient.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.recipient.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.recipient.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-12 col-sm-6 col-md-8">
                      <div class="mb-3 position-relative">
                        <code> * </code>หมายเหตุ:
                        <b-form-textarea
                          v-model="note"
                          rows="3"
                          max-rows="6"
                          :class="{
                            'is-invalid': submitform && $v.note.$error,
                          }"
                        ></b-form-textarea>
                        <div
                          v-if="submitform && $v.note.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.note.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <b-button
                    class="btn float-end m-1"
                    variant="primary"
                    type="submit"
                    @click="tooltipForm()"
                  >
                    <!-- <i class="uil-search-alt"></i> -->
                    บันทึก
                  </b-button>
                  <b-button
                    v-if="status == 'DFT'"
                    class="btn float-end m-1"
                    variant="danger"
                    type="submit"
                    @click="submitCancel()"
                  >
                    <!-- <i class="uil-search-alt"></i> -->
                    ยกเลิกใบสั่งซ่อม
                  </b-button>
                  <b-button
                    v-if="status != 'APV' && status != 'DFT'"
                    class="btn float-end m-1"
                    variant="danger"
                    type="submit"
                    @click="submitApv()"
                  >
                    <!-- <i class="uil-search-alt"></i> -->
                    ยืนยันการยกเลิกใบสั่งซ่อม
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
