var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-overlay',{attrs:{"show":_vm.overlayFlag,"spinner-variant":this.$overlayVariant,"spinner-type":this.$overlayType,"rounded":this.$overlayRounded}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"m-4"},[_c('h4',[(_vm.status == 'SUBMIT')?_c('span',{staticClass:"badge bg-info"},[_vm._v("รอยืนยันการยกเลิกใบสั่งซ่อม")]):_vm._e()]),_c('h4',[(_vm.status == 'DFT')?_c('span',{staticClass:"badge bg-warning"},[_vm._v("ร่าง")]):_vm._e()]),_c('h4',[(_vm.status == 'APV')?_c('span',{staticClass:"badge bg-danger"},[_vm._v("ยกเลิกใบสั่งซ่อมเรียบร้อยแล้ว")]):_vm._e()])]),_c('hr'),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12 align-items-center"},[_c('div',{staticClass:"row align-items-start"},[_c('div',{staticClass:"col-6 col-sm-6 col-md-6"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("วันที่ยกเลิกใบสั่งซ่อม: "),_c('date-picker',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.cancelDate.$error,
                        },attrs:{"format":"YYYY-MM-DD","value-type":"format"},model:{value:(_vm.cancelDate),callback:function ($$v) {_vm.cancelDate=$$v},expression:"cancelDate"}}),(_vm.submitform && _vm.$v.cancelDate.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.cancelDate.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-6 col-sm-6 col-md-6"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("ผู้รับ: "),_c('multiselect',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.recipient.$error,
                        },attrs:{"label":"name","options":_vm.rowsUser,"custom-label":_vm.customLabel,"show-labels":false,"open-direction":"bottom","placeholder":""},model:{value:(_vm.recipient),callback:function ($$v) {_vm.recipient=$$v},expression:"recipient"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("ไม่พบข้อมูล")])]),(_vm.submitform && _vm.$v.recipient.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.recipient.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-12 col-sm-6 col-md-8"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("หมายเหตุ: "),_c('b-form-textarea',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.note.$error,
                        },attrs:{"rows":"3","max-rows":"6"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),(_vm.submitform && _vm.$v.note.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.note.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('b-button',{staticClass:"btn float-end m-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){return _vm.tooltipForm()}}},[_vm._v(" บันทึก ")]),(_vm.status == 'DFT')?_c('b-button',{staticClass:"btn float-end m-1",attrs:{"variant":"danger","type":"submit"},on:{"click":function($event){return _vm.submitCancel()}}},[_vm._v(" ยกเลิกใบสั่งซ่อม ")]):_vm._e(),(_vm.status != 'APV' && _vm.status != 'DFT')?_c('b-button',{staticClass:"btn float-end m-1",attrs:{"variant":"danger","type":"submit"},on:{"click":function($event){return _vm.submitApv()}}},[_vm._v(" ยืนยันการยกเลิกใบสั่งซ่อม ")]):_vm._e()],1)])])])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }